<template>
  <FactorList />
</template>

<script>
  export default {
    components: {
      FactorList: () => import('@/components/stock/factor/FactorList')
    }
  }
</script>